<template>
    <div id="header">
        <div class="container-fluid">
            <b-row class="py-2">
                <b-col sm="12" md="2" lg="2">
                    <img src="@/assets/img/logo.png" alt="MaryHelp Manuais" title="MaryHelp Manuais" class="w-100" />
                </b-col>
                <b-col sm="12" md="10" lg="10" class="d-flex align-items-center justify-content-end py-3 action-buttons">                
                    <router-link class="btn btn-outline-mary-help mr-2" to="/">Lista de Manuais</router-link>
                    <b-dropdown v-if="IsProfileAdministrador()" right variant="mary-help">
                        <template #button-content>
                            <b-icon icon="clipboard-plus" class="mr-2" />Adicionar
                        </template>
                        <b-dropdown-item href="/admin/manuais"><b-icon icon="arrow-right-short" /> Manuais</b-dropdown-item>
                        <b-dropdown-item href="/admin/modulos"><b-icon icon="arrow-right-short" /> Módulos</b-dropdown-item>
                    </b-dropdown>

                    <b-button v-if="false" variant="link" class="text-mary-help-purple ml-4 pl-4 button-exit"><b-icon icon="door-open" class="mr-2" />Sair</b-button>
                </b-col>
            </b-row>

            <b-row v-if="!$route.meta.hideBanner">
                <b-col sm="12" md="12" lg="12" class="header-title py-5">
                    <h1 class="py-5">Manual <span>Franqueados</span></h1>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import PermissionManager from '@/mixins/PermissionManager'

export default {
    mixins: [ PermissionManager ]
}
</script>

<style scoped lang="scss">
    #header {
        background-color: #fff;
    }

    .button-exit {
        border-left: solid 1px #F872BF !important;
    }

    .header-title {
        background-image: url("~@/assets/img/fundo-banner.png");
        background-size: cover;
        text-align: center;

        h1 {
            color: #8C51A1;

            span {
                text-transform: uppercase;
                font-weight: 800;
            }
        }
    }

    @media screen and (max-width: 767px) {
        .action-buttons {
            justify-content: center !important;
        }
    }
</style>