<template>
    <div id="dashboard" class="py-3" v-if="module && module.training">
        <b-row>
            <b-col sm="12" lg="4" md="4">
                <b-list-group>
                    <b-list-group-item :class="item.id == $route.params.id_module ? 'active' : ''" v-for="(item, index) in module.training.modules" :key="index" :href="item.id != $route.params.id_module ? `/manual/${module.training.id}/modulo/${item.id}` : ''">
                        <fa style="vertical-align: middle; font-size: 22px" icon="angle-right" /> {{ item.title }}
                    </b-list-group-item>
                </b-list-group>
            </b-col>

            <b-col sm="12" lg="8" md="8">
                <b-card class="mb-2">
                    <h1>{{module.training.title}} <fa style="vertical-align: middle; font-size: 22px" icon="angle-right" /> <strong>{{ module.title }}</strong></h1>
                    <hr>
                    <b-row class="form-group" v-if="Files.length > 0">
                        <b-col md="12">
                            <h4>Arquivos</h4>
                            <div v-if="Files.length == 0" class="alert alert-warning">
                                Nenhum arquivo encontrado
                            </div>
                            <b-row v-else>
                                <b-col md="3" v-for="(file, i) in Files" :key="i" class="text-center">
                                    <a target="_blank" class="card-file" v-if="file.extension == 'pdf'" :href="'/manual/'+module.training.id+'/modulo/'+module.id+'/pdf/'+file.id">
                                        <fa :icon="file.icon"></fa><br>
                                        {{file.title}}
                                    </a>
                                    <a v-else :download="file.title+'.'+file.extension" :href="(file.absolute ? '' : module.aws_s3_base_url+'/')+file.src" class="card-file">
                                        <fa :icon="file.icon"></fa><br>
                                        {{file.title}}
                                    </a>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <br>
                    <b-row class="form-group" v-if="Videos.length > 0">
                        <b-col md="12">
                            <h4>Videos</h4>
                            <div v-if="Videos.length == 0" class="alert alert-warning">
                                Nenhum arquivo encontrado
                            </div>
                            <b-row v-else>
                                <b-col md="3" v-for="(video, i) in Videos" :key="i" class="text-center">
                                    <a data-fancybox="gallery" :data-caption="video.title" :href="(video.absolute ? '' : module.aws_s3_base_url+'/')+video.src" class="card-file video">
                                        <fa icon="file-video"></fa><br>
                                        {{video.title}}
                                    </a>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <br>
                    <b-row class="form-group" v-if="Pages.length > 0">
                        <b-col md="12">
                            <h4>Paginas</h4>
                            <div v-if="Pages.length == 0" class="alert alert-warning">
                                Nenhum arquivo encontrado
                            </div>
                            <b-row v-else>
                                <b-col md="3" v-for="(Page, i) in Pages" :key="i" class="text-center">
                                    <a target="_blank" :href="'/manual/'+Page.src" class="card-file">
                                        <b-icon icon="book" class="mr-2" /><br>
                                        {{Page.title}}
                                    </a>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    components: {
    },
    computed: {
        Attachments() {
            var attachments = [];
            if (this.module && this.module.files) {
                attachments = this.module.files.map(f => {
                    var extension = f.file ? f.file.match(/[^.]+$/)[0] : "";
                    extension = extension ? extension.trim() : "";

                    var icon = "file";
                    if (extension.match(/csv/i)) {
                        icon = "file-csv";
                    }
                    else if (extension.match(/xlsx?/i)) {
                        icon = "file-excel";
                    }
                    else if (extension.match(/file-pdf-o/i)) {
                        icon = "file-pdf";
                    }
                    
                    return {
                        id: f.id,
                        title: f.title,
                        extension: extension.toLowerCase(),
                        icon: icon,
                        absolute: /https?:\/\//i.test(f.file),
                        src: f.file
                    }
                });
            }

            return attachments;
        },
        Pages() {
            var pages = this.Attachments.filter(f => f.src.match(/^modulo-(basico|limpeza|lavanderia)/i));
            pages = pages.sort((a, b) => a.title > b.title ? 1 : -1);
            return pages;
        },
        Files() {
            var self= this;
            var files = this.Attachments.filter(f => self.Pages.filter((p) => p == f).length == 0 && !f.extension.match(/webm|mkv|flv|vob|ogv|ogg|avi|MTS|M2TS|TS|mov|qt|wmv|rmvb|mp4|m4p|m4v|mpe?g|3gp/i));
            files = files.sort((a, b) => a.title > b.title ? 1 : -1);
            return files;
        },
        Videos() {
            var self= this;
            var videos = this.Attachments.filter(f => self.Pages.filter((p) => p == f).length == 0 && f.extension.match(/webm|mkv|flv|vob|ogv|ogg|avi|MTS|M2TS|TS|mov|qt|wmv|rmvb|mp4|m4p|m4v|mpe?g|3gp/i));
            videos = videos.sort((a, b) => a.title > b.title ? 1 : -1);
            return videos;
        }
    },
    data() {
        return {
            loadingData: false,
            module: {},
        }
    },
    created() {
        this.LoadData();
    },
    mounted() {
        
    },
    methods: {
        LoadData() {
            this.loadingData = true;
            this.$store.dispatch('module/getModuleById', this.$route.params.id_module).then(data => {
                this.loadingData = false;
                this.module = data.module ? data.module : [];
            });
        }
    }
}
</script>

<style scoped>
    h1 {
        font-size: 26px;
        color: #823D9A;
    }

    h4 {
        display: inline-block;
        font-size: 18px;
        font-weight: bold;
        border-bottom: 3px solid #AAA;
        padding-bottom: 4px;
        margin-bottom: 20px;
    }

    .card-file > svg {
        font-size: 30px;
        margin-bottom: 8px;
        color: #f872bf;
    }

    .card-file.video > svg {
        color: #61BEE2;
    }

    .card-file {
        color: #000;
        text-decoration: none;
        cursor: pointer;
    }
</style>