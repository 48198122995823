<template>
    <div v-if="pdf_show">
        <pdf style="border: 1px solid #ddd;" :src="pdf_show" @num-pages="pdf_pages = $event" ></pdf>
        <template v-if="pdf_pages > 0">
            <pdf style="border: 1px solid #ddd;" :src="pdf_show" v-for="i in pdf_pages - 1" :page="i + 1" :key="i"></pdf>
        </template>
    </div>
    <b-overlay v-else show rounded="sm">
        <div class="loading"></div>
    </b-overlay>
</template>

<script>
import pdf from "vue-pdf";

export default {
    components: {
        pdf
    },
    data() {
        return {
            id_module: this.$route.params.id_module,
            id_file: this.$route.params.id_file,
            pdf_pages: 0,
            pdf_show: null
        }
    },
    mounted() {
        this.LoadData();
    },
    methods: {
        async LoadData() {
            this.pdf_show = null;
            try {
                const data = await this.$store.dispatch('module/getPdf', {
                    module_id: this.id_module, 
                    file_id: this.id_file
                });

                this.pdf_show = URL.createObjectURL(data);
            }
            catch(e) {
                this.pdf_show = null;
            }
        }
    }
};
</script>

<style scoped>
    .slides > section {
        top: 0% !important;
        height: 50vh;
        overflow: auto;
    }

    .loading {
        width: 100vw;
        height: 100vh;
    }
</style>